.alert {
    width: 80%;
    padding: 1.25rem;
    margin: 1rem auto;
    position: fixed;
    top: 25px;
    z-index: 2030;
    display: flex;
    align-items: center;
    align-self: center;
}

@include media-breakpoint-up(md) {
    .alert {
        top: 50px;
    }
}