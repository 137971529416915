.last-projects {
    padding: 60px 0;
    text-align: center;
}

.last-projects__title {
    font-size: 1.875rem;
    margin-bottom: 1.25rem;
}

.last-projects__description {
    font-size: 0.875rem;
    line-height: 1.7;
    margin-bottom: 3.375rem;
}

.last-projects__card {
    width: 100%;
    height: 378px;
}

.last-projects__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.last-projects__card-button {
    bottom: 1.875rem;
}

@include media-breakpoint-up(lg) {
    .last-projects {
        text-align: left;
    }

    .last-projects__title {
        font-size: 4.375rem;
        margin-bottom: 1.875rem;
    }
    
    .last-projects__description {
        width: 50%;
        font-size: 1rem;
        line-height: 1.7;
        margin-bottom: 3.75rem;
    }
}

@include media-breakpoint-up(xl) {
    .last-projects .swiper-navigation {     
        & > .slider-button-prev {
          left: -3.5rem;
        }
    
        & > .slider-button-next {
          right: -3.5rem;
        }
      }
}