.team {
    padding: 60px 0;
}

.team__section-name {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
}

.team__title {
    font-size: 1.875rem; // 30px
    margin-bottom: 1.25rem; // 20px
}

.team__description {
    max-width: 750px;
    margin: 0 auto 40px auto;
}

.team__member {
    position: relative;
    height: 426px;
}

.team__member > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    position: absolute;
}

.team__member__content {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(5, 10, 48, 0) 0%, rgba(5, 10, 48, 0.4) 76.1%, $dark 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.team .swiper {
    width: 100%;
}

@include media-breakpoint-up(sm) {
    .team .swiper {
        width: 80%;
    }

}

@include media-breakpoint-up(xl) {

    .team {
        padding: 140px 0 91px 0;
    }

    .team__section-name {
        font-size: 1rem;
        margin-bottom: 10px;
    }
    
    .team__title {
        font-size: 3.75rem; // 60px
        margin-bottom: 1.875rem; // 30px
    }
    
    .team__description {
        max-width: 750px;
        margin-bottom: 60px;
    }
    
}

@include media-breakpoint-up(xxl) {
    .team .swiper {
        width: 100%;
    }
}
