.home-header {
  position: relative;
  padding-bottom: 120px;
  width: 100%;
}

.home-header__media {
  position: absolute;
  top: 0;
  width: 100%;
  height: 652px;
}

.home-header__video,
.home-header__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home-header__content {
  position: relative;
  padding-top: 52px;
  width: 100%;
  height: 652px;
  background-image: linear-gradient(
    180deg,
    rgba(5, 10, 48, 0) 0%,
    rgba(5, 10, 48, 0.4) 76.1%,
    #050a30 100%
  );
}

.home-header__content > .container {
  height: 100%;
}

.home-header__content h1 {
  font-size: 1.875rem;
}

.home-header__content > button {
  font-size: 1rem;
}

.home-header {
  & .about__content {
    width: 100%;
  }

  & .about__media {
    width: 100%;
  }

  & .about__media img {
    width: 100%;
    max-width: 705px;
  }

  & .about__section-name {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 1rem;
  }

  & .about__title {
    font-size: 1.875rem;
  }

  & .about__description {
    font-size: 0.875rem;
  }

  & .read-more {
    font-size: 1rem;
    font-weight: 700;
  }
}

.home-header .slider {
  position: relative;
  top: -80px;
}

.home-header .swiper {
  & .swiper-slide__body {
    padding-top: 0.625rem;
  }

  & .swiper-slide__media {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  & .swiper-slide__body > h2 {
    font-size: 1.125rem;
  }

  & .swiper-slide__body a {
    font-size: 0.75rem;
  }
}

.home-header .swiper-navigation {     
  & > .slider-button-prev {
    top: -1rem;
  }

  & > .slider-button-next {
    top: -1rem;
  }
}

@include media-breakpoint-up(md) {
  .home-header__media,
  .home-header__video,
  .home-header__content {
    height: 1004px;
  }

  .home-header__content {
    padding-top: 100px;
  }

  .home-header__content h1 {
    font-size: 2.5rem;
    line-height: 1.2;
    max-width: 1008px;
    width: 80%;
  }

}

@include media-breakpoint-up(lg) {
  .home-header__content h1 {
    font-size: 4rem;
    line-height: 1.2;
    max-width: 1008px;
  }

  .home-header__swiper { 
    & .swiper-slide__body {
      padding-top: 1.25rem;
    }
  }

  .home-header {
    & .about__content {
      width: 50%;
    }

    & .about__media {
      width: 50%;
    }
  }

  
}

@include media-breakpoint-up(xl) {
  .home-header__content h1 {
    font-size: 5.25rem;
    line-height: 1.155;
    max-width: 1008px;
  }

  .home-header .swiper-navigation {     
    & > .slider-button-prev {
      left: -3.5rem;
    }

    & > .slider-button-next {
      right: -3.5rem;
    }
  }

  .home-header__about {
    margin-top: 100px;
  }

  .about__title {
    font-size: 3.75rem;
  }

  .about__description {
    font-size: 1rem;
  }
}
