.experience {
    padding: 60px 0;
}

.experience__section-name {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.625rem; // 10px
}

.experience__title {
    font-size: 1.875rem; // 30px
    margin-bottom: 1.25rem; // 20px
}


.experience__description {
    font-size: 0.875rem;
    margin-bottom: 1.375rem; // 22px
}

.experience__read-more {
    margin-bottom: 2rem; // 32px
}

.experience__stat > p:first-child {
    font-size: 2.5rem; // 40px
    margin-bottom: 0.625rem; // 10px
}

.experience__stat > :last-child {
    font-size: 1rem;
}

@include media-breakpoint-up(xl) {
    .experience {
        padding: 140px 0;
    }
    
    .experience__title {
        font-size: 3.75rem; // 60px
        margin-bottom: 2.8125rem; // 45px
    }
    
    .experience__description {
        font-size: 1rem;
        margin-bottom: 2.937547rem; // 47px
    }
    
    .experience__read-more {
        margin-bottom: 2.625rem; // 42 px
    }

    .experience__stat > :first-child {
        font-size: 3.75rem; // 60px
    }

    
}
