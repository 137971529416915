.navbar {
  background-image: linear-gradient(
    180deg,
    #050a30 0%,
    rgba(5, 10, 48, 0.166667) 99.99%,
    rgba(5, 10, 48, 0) 100%
  );
  height: 52px;
  padding: 0;
}

.navbar-toggler {
  height: 52px;
}

.navbar-logo {
  width: 85px;
}

.navbar-logo--mobile {
  width: 142px;
  height: 125px;
}

@include media-breakpoint-up(md) {
  .navbar {
    height: 100px;
  }

  .navbar-logo {
    width: 161px;
  }
}
