.immo-container {
    margin-top: -3rem;
    margin-bottom: 6rem;
}

.immo-description {
    width: 100%;
}

.immo-description__title {
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
}

.immo-media__images {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.immo-media__image {
    width: 75%;
    max-width: 400px;
    max-height: 300px;

    &>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.image__first {
    align-self: flex-end;
    margin-bottom: 1rem;

}

.image__second {
    align-self: flex-start;
}

@include media-breakpoint-up(lg) {
    .immo-container {
        margin-top: 2rem;
        margin-bottom: 6rem;
    }

    .immo-media {
        margin-top: 100px;
    }

    .immo-description__content::before {
        width: 3rem;
        content: "";
        display: block;
        border-top: solid 3px $primary;
        transform: translateX(-4rem) translateY(0.675rem);
    }

    .immo-description {
        width: 50%;
        flex-basis: 0;
        flex-grow: 1;
        margin-left: 6rem;
    }

    .immo-description__title {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }

    .immo-media {
        width: 50%;
        flex-basis: 0;
        flex-grow: 1;
        order: -1;
    }
}