.more-container {
    margin-bottom: 3rem;
}

.more {
    display: flex;
    flex-direction: column;
}

.more-header {
    width: 100%;
}

.more__title {
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
}

.more__content > section {
    margin-bottom: 2rem;
}

.more__section-title {
    text-transform: uppercase;
    border-left: solid $primary 4px;
    padding-left: 1rem;
    font-size: 1.25rem;
    line-height: 2;
}

.more__map {
    width: 100%;
}

.more__map > iframe {
    width: 100%;
}

@include media-breakpoint-up(lg) {
    .more__content {
        display: flex;
        flex-wrap: wrap;
    }

    .more__overview {
        width: 50%;
    }

    .more__financial {
        width: 50%;
    }

    .more__location {
        width: 100%;
    }

    .more__commodities {
        width: 50%;

        & ul {
            columns: 2;
        }
    }
}