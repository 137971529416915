.services {
    padding: 60px 0;
}

.services__section-name {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
}

.services__title {
    margin-bottom: 20px;
}

.services__description {
    max-width: 750px;
    margin: 0 auto 40px auto;
}

.services__cards {
    width: 100%;
}

.services__cards .swiper {
    width: 100%;
}

.services__cards .swiper-slide {
    height: auto !important;
}


.services__card {
    border: 1px solid $square-dark-blue;
    padding: 2.75rem;
    height: 100%;
}

.services__card:hover {
    border: 1px solid $primary;
}

.services__card-icon {
    margin-left: 0.25rem;
    margin-bottom: 1.25rem;
}

.services__card-title {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
}

.services__card-description {
    margin-bottom: 1.375rem;
}

@include media-breakpoint-up(sm) {
    .services__cards .swiper {
        width: 80%;
    }

    .services__card {
        padding: 1.25rem;
    }
}

@include media-breakpoint-up(lg) {
    .services__cards .swiper {
        width: 90%;
    }
}

@include media-breakpoint-up(xl) {
    .services {
        padding: 8.75rem 0;
    }
    
    .services__title {
        font-size: 4.3125rem;
        margin-bottom: 1.875rem;
    }
    
    .services__description {
        margin-bottom: 8.75rem;
    }

    .services__cards .swiper {
        width: 100%;
    }
    
    .services__card {
        border: 1px solid $square-dark-blue;
        padding: 1.25rem;
    }
    
    .services__card:hover {
        border: 1px solid $primary;
    }
    
    .services__card-icon {
        margin-left: 0.25rem;
        margin-bottom: 1.25rem;
    }
    
    .services__card-title {
        margin-bottom: 1.25rem;
    }
    
    .services__card-description {
        margin-bottom: 1.375rem;
    }
}
