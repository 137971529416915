.immo-header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.immo-header__hero {
    position: relative;
    width: 100%;
    height: 658px;
}

.immo-header__media {
    position: absolute;
    width: 100%;
    height: 100%;
}

.immo-header__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.immo-header__content {
    position: relative;
    width: 100%;
    height: 100%;
    background-image:
        linear-gradient(180deg, rgba(5, 10, 48, 0) 0%, rgba(5, 10, 48, 1) 90%);
}

.immo-header__content>.container {
    width: 100%;
    height: 100%;
}

.immo-header__title {
    font-size: 1.875rem;
    margin-bottom: 1.25rem;
}

.immo-header__description {
    font-size: 0.875rem;
}

.immo-header__slider {
    position: absolute;
}

.immo-header__slide {
    max-height: 700px;
}

.immo-header__slide img {
    cursor: pointer;
}

.immo-header__slider .swiper {
    width: 100%;
    height: auto;
}

.immo-header__slide {
    position: relative;
}

.immo-header__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.immo-header__slide h2 {
    font-size: 1.625rem;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
}

.immo-header__slide .btn {
    font-size: 1rem;
    position: absolute;
    bottom: 1.25rem;
    left: 1.25rem;
    right: 1.25rem;
}

.immo-box {
    background-color: rgba($dark, 0.95);
    color: white;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transform: translateY(-50%);

    &>h2 {
        color: $primary;
        font-size: 1.875rem;
        margin-bottom: 1rem;
    }

    &>p {
        font-size: 1.125rem;
    }
}

.immo-box__price {
    border: solid 1px $primary;
    padding: 0.5rem;
}

.immo-box.immo-box-article {
    position: relative;
    transform: none;
    width: 300px;
    height: 180px;
    margin-top: -100px;
    margin-bottom: 20px;



    &>h4 {
        color: $primary;
    }


}



@include media-breakpoint-up(lg) {

    .immo-box {
        position: absolute;
    }


    .immo-header__hero {
        position: relative;
        width: 100%;
        height: 1004px;
    }

    .immo-header__title {
        font-size: 5.25rem;
        margin-bottom: 2.5rem;
    }

    .immo-header__description {
        font-size: 1.125rem;
    }

    .immo-header__slide h2 {
        font-size: 2rem;
        position: absolute;
        top: 1.875rem;
        left: 1.875rem;
    }

    .immo-header__slide .btn {
        font-size: 1rem;
        position: absolute;
        bottom: 1.875rem;
        left: 1.875rem;
        width: 12.5rem;
    }
}