.slider {
    width: 100%;
    position: relative;
    display: flex;

    align-items: center;
}

.swiper {
    width: 100%;
}

.swiper-navigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 10;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}


.swiper-slide.swiper-slide__height {
    max-height: 300px;
    height: auto;
}


.slider-button-prev {
    position: relative;
    cursor: pointer;
}

.slider-button-next {
    position: relative;
    cursor: pointer;
}