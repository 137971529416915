$square-gold: #d39f70;
$square-dark-blue: #050a30;
$square-blue: #204564;
$square-white: #fff;
$square-black: #000;
$square-dark-grey: #5f5e60;
$square-medium-grey: #959595;
$square-light-grey: #d4d4d4;
$square-light-bg: #f7f7f7;

$primary: $square-gold;
$secondary: $square-dark-blue;
$info: $primary;
$light: $square-white;
$dark: $square-dark-blue;

$font-family-sans-serif: source-sans-pro, sans-serif;
$font-family-monospace: Sora, monospace;
$headings-font-family: "BodoniFLF", serif;
$headings-font-style: normal;
$headings-font-weight: 700;
$headings-line-height: 1.2;

$link-decoration: none;

$display-font-family: $headings-font-family;
$display-font-style: $headings-font-style;
$display-font-weight: $headings-font-weight;
$display-line-height: $headings-line-height;

$custom-colors: (
  "square-gold": $square-gold,
  "square-dark-blue": $square-dark-blue,
  "square-blue": $square-blue,
  "square-dark-grey": $square-dark-grey,
  "square-medium-grey": $square-medium-grey,
  "square-light-grey": $square-light-grey,
  "square-light-bg": $square-light-bg,
  "white": #fff,
  "black": #000
);

$font-size-base: 1rem;
$custom-font-sizes: (
  7: $font-size-base * 0.875,// 14px
  8: $font-size-base * 0.75, // 12px
  9: $font-size-base * 0.625 // 10px
);
$body-color: $square-black;
$body-bg: $square-white;
$navbar-dark-hover-color: $primary;
$navbar-dark-active-color:  $primary;
$navbar-dark-color: white;
$component-active-color: $primary;

// Styling of forms
$input-bg: transparent;
$input-border-color: $primary;
$input-border-radius: 0;
$input-placeholder-color: white;
$input-color: white;