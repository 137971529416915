.vision {
    margin-top: 3.75rem;
    margin-bottom: 2rem;
}

.vision__media {
    width: 100%;
    padding-bottom: 2.5rem;
    background-image:
        linear-gradient(180deg, white 0%, white 60%, $square-light-bg 60%, $square-light-bg 100%);
}

.vision__image {
    display: block;
    width: 80%;
    max-width: 582px;
}

.vision__title {
    font-size: 1.875rem;
    line-height: 1.2;
    margin-bottom: 1.875rem;
}

.vision__description {
    font-size: 1rem;
    line-height: 1.25;
}

.vision__content {
    width: 100%;
    background-color: $square-light-bg;
}

@include media-breakpoint-up(lg) {
    .vision {
        margin-top: 10rem;
        margin-bottom: 0;
        background-image: 
            linear-gradient(90deg, white 0%, white calc(50% - 4rem), $square-light-bg calc(50% - 4rem), $square-light-bg 100%);
    }

    .vision__media {
        width: 50%;
        background-image: none;
    }

    .vision__image {
        width: 100%;
        max-width: 582px;
    }

    .vision__content {
        max-width: 622px;
        padding: 4.75rem 0 4.75rem 4rem;
        width: 50%;
    }

    .vision__title {
        font-size: 3.75rem;
        margin-bottom: 3.75rem;
    }

    .vision__description {
        font-size: 1rem;
        line-height: 1.875;
    }

    .vision__description p {
        margin-bottom: 1.875rem;
    }
}
