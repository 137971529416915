@font-face {
  font-family: BodoniFLF;
  font-style: normal;
  font-weight: 700;
  src: url("../../vendors/fonts/BodoniFLF-Bold.ttf") format("truetype");
}

@font-face {
  font-family: BodoniFLF;
  font-style: italic;
  font-weight: 700;
  src: url("../../vendors/fonts/BodoniFLF-BoldItalic.ttf") format("truetype");
}
