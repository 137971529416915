.testimonials {
    padding-bottom: 3.75rem;
    position: relative;
}

.testimonials__section-name {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
}

.testimonials__title {
    font-size: 1.875rem;
    margin-bottom: 2.5rem;
}

.testimonials__card {
    background-color: white;
    padding: 3rem;
    height: 100%;
}

.testimonials .swiper-slide {
    height: auto;
}

.testimonials__body {
    margin-bottom: 30px;
}

.testimonials__photo {
    width: 60px;
    height: 60px;
}

.testimonials__name {
    font-size: 1rem;
    margin-bottom: 4px;
}

.testimonials__role {
    font-size: 14px;
}

.testimonials .swiper {
    width: 100%;
}

.testimonials {
    .swiper {
        width: 100%;
        height: auto !important;
    }

    .slider-button-prev path {
        fill: $dark;
    }

    .slider-button-next path {
        fill: $dark;
    }
}

@include media-breakpoint-up(sm) {
    .testimonials {
        .swiper {
            width: 80%;
        }
    
        .slider-button-prev path {
            fill: white;
        }
    
        .slider-button-next path {
            fill: white;
        }
    }
}

@include media-breakpoint-up(lg) {
    .testimonials {
        padding-bottom: 8.75rem;
        position: relative;
    }
    
    .testimonials__title {
        font-size: 3.75rem;
        margin-bottom: 3.75rem;
    }

    .testimonials__card {
        padding: 1.25rem;
    }

    .testimonials {
        .swiper {
            width: 90%;
        }
    }
}