.about-header {
  position: relative;
  width: 100%;
}

.about-header__media {
  position: absolute;
  top: 0;
  width: 100%;
  height: 658px;
}

.about-header__image,
.about-header__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.about-header__content {
  position: relative;
  width: 100%;
  height: 658px;
  background-image: linear-gradient(
    180deg,
    rgba(5, 10, 48, 0) 0%,
    rgba(5, 10, 48, 0.4) 76.1%,
    $dark 100%
  );
}

.about-header__content > .container {
  height: 100%;
}

.about-header__title {
  font-size: 1.875rem;
  margin-bottom: 20px;
}

.about-header__description {
  font-size: 0.875rem;
  margin-bottom: 20px;
}

.about-header__card > .container {
  position: relative;
  top: -100px;
}

.about-header__card-media {
  width: 100%;
  height: 359px;
}

.about-header__card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.about-header__card-body {
  padding: 1.875rem 1rem;
  font-size: 1rem;
}

.about-header__card-title {
  margin-bottom: 1.25rem;
  font-size: 1.875rem;
}

@include media-breakpoint-up(md) {
  .about-header__media,
  .about-header__image,
  .about-header__content {
    height: 1004px;
  }

  .about-header__title {
    font-size: 5.25rem;
    line-height: 1.2;
    max-width: 1004px;
    width: 80%;
    margin-bottom: 40px;
  }

  .about-header__description {
    font-size: 1.125rem;
    max-width: 1004px;
    width: 80%;
    margin-bottom: 40px;
  }

  .about-header__card-media {
    width: 100%;
    height: 559px;
  }
}

@include media-breakpoint-up(lg) {
  .about-header__title {
    font-size: 5.25rem;
    line-height: 1.2;
    max-width: 1008px;
    width: 80%;
    margin-bottom: 40px;
  }

  .about-header__card-title {
    margin-bottom: 5rem;
  }

  .about-header__card-description > p {
    margin-bottom: 1.25rem;
  }
}

@include media-breakpoint-up(xl) {
  .home-header__content {
    & > h1 {
      font-size: 5.25rem;
      line-height: 1.155;
      max-width: 1004px;
    }
  }

  .about-header__card > .container {
    width: 80%;
  }
}
