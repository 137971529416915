.contact {
    position: relative;
    background-color: $dark;
    padding-bottom: 2.5rem;
}

.contact__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 556px;
}

.contact__background > img {
    width: 100%;
    height: 556px;
    object-fit: cover;
    object-position: center;
}


.contact__content {
    font-size: 0.875rem;
    position: relative;
    width: 100%;
    padding-top: 111px;
    min-height: 556px;
    background-image: 
        linear-gradient(180deg, rgba(5, 10, 48, 0) 0%, rgba(5, 10, 48, 0.4) 76.1%, $dark 100%),
        linear-gradient(90deg, $dark 11.48%, rgba(5, 10, 48, 0) 59.55%);
    background-size: 100% 556px;
    

    & > h2 {
        font-size: 1.875rem;
    }  

    & > h3 {
        font-size: 1.5rem;
    } 
}

.contact__content > .container {
    margin-top: 52px;
}

.contact__social {
    margin-bottom: 1.25rem;
}

.contact__title,
.contact__subtitle {
    margin-bottom: 0.625rem;
}


.contact {
    & .facebook-logo,
    & .twitter-logo,
    & .instagram-logo {
        width: 20;
        height: 20;
    }
}

@include media-breakpoint-up(xl) {
    .contact__content {
        font-size: 1rem;

        & > h2 {
            font-size: 3.75rem;
        } 
        & > h3 {
            font-size: 3rem;
        } 
    }

    .contact__content > .container {
        margin-top: 100px;
    }

    .contact {
        & .facebook-logo,
        & .twitter-logo,
        & .instagram-logo {
            width: 24;
            height: 24;
        }
    }

    .contact__social {
        margin-bottom: 1.5rem;
    }
    
    .contact__title,
    .contact__subtitle {
        margin-bottom: 1.875rem;
    }
}