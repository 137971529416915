$text-sizes: (
    1: 2.5,
    2: 2,
    3: 1.75,
    4: 1.5,
    5: 1.25,
    6: 1,
    7: 0.875,
    8: 0.75,
    9: 0.625,
    26: 1.625,
);

@each $size, $factor in $text-sizes {
    .text-size-#{$size} {
        font-size: $factor * $font-size-base;
    }
}

@each $name, $breakpoint in $grid-breakpoints {
    @each $size, $factor in $text-sizes {
        @media (min-width: $breakpoint) {
            .text-size-#{$name}-#{$size} {
                font-size: $factor * $font-size-base;
            }
        }
    }
}

flex-basis-0 {
    flex-basis: 0;  
}

flex-basis-auto {
    flex-basis: auto;  
}
