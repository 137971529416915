.articles {
    position: relative;
    // top: -13.5rem;
}


.articles__section-name {
    font-size: 1rem;
    margin-bottom: 0.625rem;
}

.articles__title {
    font-size: 1.875rem;
    margin-bottom: 1.25rem;
}

.articles__description {
    font-size: 1rem;
    margin-bottom: 2.5rem;
}

.articles__categories {
    width: 100%;
    font-size: 0.875rem;
    padding-bottom: 1.875rem;
}

.articles__category-link {
    color: white;
    font-weight: 600;
}

.articles__category-link:hover,
.articles__category-link:focus {
    color: $primary;
}

.articles__category-link.active {
    color: $primary;
}

.articles__cards {
    background-image:
        linear-gradient(180deg, $dark 0%, $dark 50%, white 50%, white 100%);
    background-size: 100% 17rem;
    background-repeat: no-repeat;
}

.article-card__media {
    width: 100%;
    height: 17rem;
    margin-bottom: 1.25rem;
}

.article-card__media > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.article-card__content h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

.article-card__content p {
    font-size: 1rem;
    margin-bottom: 1.875rem;
}

.article-card__content a {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.875rem;
}


@include media-breakpoint-up(lg) {
    .articles__title {
        font-size: 3.75rem;
        margin-bottom: 1.875rem;
    }

    .articles__description {
        margin-bottom: 3.75rem;
    }

    .articles__categories {
        font-size: 1rem;
        padding-bottom: 3.75rem;
    }

    .article-card__media {
        height: 23.625rem;
    }

    .article-card__content h3 {
        margin-bottom: 0.625rem;
    }

    .article-card__content p {
        margin-bottom: 1.875rem;
    }

    .article-card__content a {
        width: 12.5rem;
    }
}
