.article-header {
    width: 100%;
    position: relative;
    margin-bottom: 4.125rem;
}

.article-header__media {
    position: absolute;
    width: 100%;
    height: 658px;
}

.article-header__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.article-header__content {
    position: relative;
    width: 100%;
    height: 658px;
    background-image: 
    linear-gradient(180deg, rgba(5, 10, 48, 0) 0%, rgba(5, 10, 48, 0.4) 76.1%, $dark 100%);;
}

.article-header__content > .container {
    width: 100%;
    height: 100%;
}

.article-header__title {
    font-size: 1.875rem;
    margin-bottom: 1.25rem;
}

.home-header__button {
    font-size: 1rem;
}

@include media-breakpoint-up(lg) {
    .article-header__media,
    .article-header__content {
        height: 1004px;
    }

    .article-header__title {
        font-size: 5.25rem;
        margin-bottom: 2.5rem;
    }
}


