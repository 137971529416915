.blog-header {
    position: relative;
    width: 100%;
    margin-bottom: -3rem;
}

.blog-header__hero {
    position: relative;
    width: 100%;
    height: 658px;
}

.blog-header__media {
    position: absolute;
    width: 100%;
    height: 100%;
}

.blog-header__image,
.blog-header__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.blog-header__content {
    position: relative;
    width: 100%;
    height: 100%;
    background-image:
        linear-gradient(180deg, rgba(5, 10, 48, 0) 0%, rgba(5, 10, 48, 0.4) 76.1%, $dark 100%);
}

.blog-header__content > .container {
    width: 100%;
    height: 100%;
}

.blog-header__title {
    font-size: 1.875rem;
    margin-bottom: 1.25rem;
}

.blog-header__description {
    font-size: 0.875rem;
}

.blog-header__slider {
    position: relative;
    transform: translateY(-50%);
}

.blog-header__slider .swiper {
    width: 80%;
}

.blog-header__slide {
    position: relative;
}

.blog-header__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.blog-header__slide h2 {
    font-size: 1.625rem;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
}

.blog-header__slide .btn {
    font-size: 1rem;
    position: absolute;
    bottom: 1.25rem;
    left: 1.25rem;
    right: 1.25rem;
}

@include media-breakpoint-up(lg) {
    .blog-header {
        margin-bottom: -2rem;
    }

    .blog-header__hero {
        position: relative;
        width: 100%;
        height: 1004px;
    }

    .blog-header__title {
        font-size: 5.25rem;
        margin-bottom: 2.5rem;
    }

    .blog-header__description {
        font-size: 1.125rem;
    }

    .swiper {
        width: 100%;
    }

    .blog-header__slide h2 {
        font-size: 2rem;
        position: absolute;
        top: 1.875rem;
        left: 1.875rem;
    }

    .blog-header__slide .btn {
        font-size: 1rem;
        position: absolute;
        bottom: 1.875rem;
        left: 1.875rem;
        width: 12.5rem;
    }
}