.article__header {
    margin-bottom: 2.5rem;
}

.article__category {
    padding: 0.3rem;
    border-radius: 0.5rem;
    border: black 2px solid;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 1.875rem;
    color: black;
}

.article__category:hover {
    border: $primary 2px solid;
    color: $primary;
}

.article__title {
    font-size: 1.875rem;
    margin-bottom: 1.5rem;

}

.article__meta {
    font-size: 1rem;
    margin-bottom: 1.5rem;

}

.article__content {
    font-size: 0.875rem;
    line-height: 1.3;
}

.article__paragraphs {
    margin-bottom: 2.5rem;
}

h2.article__subtitle {
    font-size: 1.875rem;
    margin-bottom: 2.5rem;
}

h3.article__subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

h4.article__subtitle {
    font-size: 1.25rem;
    margin-bottom: 1.875rem;
}

.article__figcaption {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.article__content p {
    margin-bottom: 1.125rem;
}

.article__quote {
    width: 100%;
    margin-bottom: 3.75rem;
    margin-left: auto;
    margin-right: auto;
}

.quote__content {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
}

.quote__author {
    font-size: 1rem;
    line-height: 2.875;
}


.article__quote p:first-of-type::before {
    content: "\"";
}


.article__quote p:last-of-type::after {
    content: "\"";
}

.article__media {
    margin-bottom: 3.75rem;
    height: 330px;
}

.article__media--fullpage {
    width: 100%;
}

.article__signature {
    margin-bottom: 2.5rem;
}

.article__image,
.article__inlineimage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.signature__media {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 0.625rem;
}

.signature__media img {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
}

.signature__media hr {
    width: 100%;
}

.signature__name {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
}

.signature__expertise {
    font-size: 0.875rem;  
}


@include media-breakpoint-up(lg) {
    .article__category {
        margin-bottom: 1.875rem;
    }

    .article__title {
        font-size: 3.75rem;
        margin-bottom: 2.5rem;
    
    }

    .article__meta {
        font-size: 1.125rem;
        margin-bottom: 1.5rem;
    
    }

    .article__content {
        font-size: 1.125rem;
        line-height: 1.4;
    }

    .article__quote {
        width: 80%;
        margin-bottom: 6.25rem;
    }
    
    .quote__content {
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1.25rem;
    }
    
    .quote__author {
        font-size: 1.25rem;
        line-height: 3.75;
    }

    .article__media {
        height: 721px;
        margin-top: 6.25rem;
        margin-bottom: 6.25rem;
    }

    h2.article__subtitle {
        font-size: 3.75rem;
        margin-bottom: 2.5rem;
    }
    
    h3.article__subtitle {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }
    
    h4.article__subtitle {
        font-size: 2rem;
        margin-bottom: 1.875rem;
    }

    .signature__media {
        width: 100%;
        height: 174px;
        margin-top: 3.75rem;
        margin-bottom: 1.25rem;
    }
    
    .signature__media img {
        position: absolute;
        width: 174px;
        height: 174px;
    }
    
    .signature__name {
        font-size: 1.125rem;
    }
    
    .signature__expertise {
        font-size: 1rem;  
    }

    .article__signature {
        margin-bottom: 3.75rem;
    }
}

@include media-breakpoint-up(xl) {
    .article__quote {
        width: 70%;
        margin-bottom: 6.25rem;
    }
}
