.address {
    padding: 60px 0 90px 0;
}

.address__title {
    font-size: 1.875rem;
    margin-bottom: 2.5rem;
}

.address h2 {
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
}

.address p {
    font-size: 1rem;
    margin-bottom: 1.875rem;
}

@include media-breakpoint-up(lg) {
    
    .address__title {
        font-size: 4rem;
        margin-bottom: 2.5rem;
    }
}